import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import contactFormLeftBackground from '../assets/contact_form_left_background.png';

const ContactFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  .main-form {
    display: flex;
    width: 1267px;
    height: 622px;
  }
  .left-section {
    width: 722px;
    background-image: url(${(props) => props.leftBackground});
    padding-left: 329px;
    padding-top: 101px;
    overflow: hidden;
    position: relative;
    .content {
      position: absolute;
      left: 329px;
      top: 101px;
    }
  }
  .right-section {
    width: 545px;
    background-color: #5a6b47;
    padding: 96px 60px;
    .separator {
      height: 21px;
    }
  }
  input {
    height: 48px;
    padding: 0px 14px;
  }
  textarea {
    height: 129px;
    padding: 14px;
    resize: none;
  }
  input,
  textarea {
    width: 426px;
    border: solid 1px #ffffff;
    outline: 0;
    background-color: transparent;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  input::placeholder,
  textarea::placeholder {
    color: #ffffff;
  }
  button {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    background: #a1a48f;
    padding: 19px 60px;
    border: 0;
    outline: 0;
  }
`;

const ContactForm = (props) => {
  return (
    <ContactFormWrapper leftBackground={contactFormLeftBackground}>
      <div className="main-form">
        <div className="left-section">
          <div className="content">
            <h1>
              Liên lạc
              <br /> ngay
            </h1>
            <p>
              0966638888
              <br />
              <br />
              Ấp Long Bình, Xã Châu Bình, Huyện
              <br /> Giồng Trôm, Bến Tre
              <br />
              <br />
              binhkhuongthon@gmail.com
            </p>
          </div>
        </div>
        <div className="right-section">
          <form>
            <input placeholder="Họ tên" />
            <div className="separator" />
            <input placeholder="Số Điện Thoại" />
            <div className="separator" />
            <input placeholder="Danh mục sản phẩm/ Dịch vụ cần tư vấn" />
            <div className="separator" />
            <textarea placeholder="Thông tin thêm" />
            <div className="separator" />
            <button>Gửi đi</button>
          </form>
        </div>
      </div>
    </ContactFormWrapper>
  );
};

ContactForm.propTypes = {};

export default ContactForm;
